import { createSlice } from "@reduxjs/toolkit";

import { IPlansSlice } from "../../interfaces/IPlan";
import {
  fetchContentDetails,
  fetchContentOverview,
  fetchPlanAdvancedSearch,
  fetchPlanDetails,
  fetchPlanMaterials,
  fetchPlanTools,
  fetchPlansList,
  fetchRelatedPlans,
  fetchSearchedPlans,
  fetchSelectedPlanMaterials,
  fetchSelectedPlanTools,
} from "../actions/PlansActions";
import { PLANS_LIST_STATE_NAME } from "../models/states";

const initialCurrentPlan = {
  title: "",
  hasPdfSheet: false,
  difficulty: 1,
  price: 0,
  projectCost: 0,
  safetyPrecautions: "",
  status: 0,
  summary: "",
  images: [],
  sheets: null,
  coverImages: [],
  planSteps: [],
  keyWords: [],
  categoryId: "",
  materials: [],
  tools: [],
};

const initialState: IPlansSlice = {
  selectedPlanInfo: {
    id: null,
    isPreview: false,
  },
  plansList: {
    loading: false,
    error: null,
    data: [],
  },
  searchedPlansList: {
    loading: false,
    error: null,
    data: [],
  },
  planDetail: {
    loading: false,
    error: null,
    data: {
      id: "",
      checkoutStatus: 0,
      title: "",
      creatorName: "",
      creatorId: "",
      rate: 0,
      rateCount: 0,
      soldCount: 0,
      hasPdfSheet: false,
      difficulty: 0,
      price: 0,
      projectCost: 0,
      safetyPrecautions: "",
      status: 0,
      summary: "",
      images: [],
      sheets: null,
      coverImages: [],
      categories: [],
      steps: [],
      totalMaterials: [],
      totalTools: [],
      keyWords: [],
      isFavorite: false,
      isPurchasedBefore: false,
    },
  },
  planMaterials: {
    loading: false,
    error: null,
    data: [],
  },
  planTools: {
    loading: false,
    error: null,
    data: [],
  },
  selectedPlanMaterials: {
    loading: false,
    error: null,
    data: [],
  },
  selectedPlanTools: {
    loading: false,
    error: null,
    data: [],
  },
  currentPlan: initialCurrentPlan,
  contentDetails: {
    loading: false,
    error: null,
    data: [],
  },
  contentOverview: {
    loading: false,
    error: null,
    data: {
      buyers: 0,
      monthlyViewsGrowth: 0,
      totalViewer: 0,
      earnings: 0,
      monthlyBoughtGrowth: 0,
    },
  },
  planAdvancedSearch: {
    loading: false,
    error: null,
    data: {
      data: [],
      totalCount: 0,
    },
    searchCriteria: {
      difficulty: null,
      subCategoryId: null,
      minPlanPrice: null,
      maxPlanPrice: null,
      projectCost: null,
      rate: null,
      orderType: null,
      lstRate: null,
      creatorId: null,
      name: null,
    },
  },
  currentPlanSteps: [],
  morePlansList: {
    loading: false,
    error: null,
    data: [],
  },
  materialsTableData: [],
  toolsTableData: [],
  planCoverImages: [],
};

export const PlansSlice = createSlice({
  name: PLANS_LIST_STATE_NAME,
  initialState,
  reducers: {
    setPlanId: (state, action) => {
      state.selectedPlanInfo = action.payload;
    },
    setNewPlan: (state, action) => {
      state.currentPlan = action.payload;
    },
    updateCurrentPlan: (state, action) => {
      const { key, value } = action.payload;

      state.currentPlan = {
        ...state.currentPlan,
        [key]: value,
      };
      return state;
    },
    updatePlanSteps: (state, action) => {
      state.planDetail.data.steps = action.payload;
    },
    updateCurrentPlanSteps: (state, action) => {
      const { steps } = action.payload;
      // Add the new steps to the current plan's steps array
      state.currentPlanSteps.push(...steps);
    },
    setCurrentPlanSteps: (state, action) => {
      state.currentPlanSteps = action.payload;
    },
    resetPlanDetail: (state) => {
      state.planDetail = initialState.planDetail;
      state.selectedPlanMaterials = initialState.selectedPlanMaterials;
      state.selectedPlanTools = initialState.selectedPlanTools;
      state.currentPlan = initialCurrentPlan;
    },
    updateSelectedPlanMaterials: (state, action) => {
      state.selectedPlanMaterials = action.payload;
    },
    updateSelectedPlanTools: (state, action) => {
      state.selectedPlanTools = action.payload;
    },
    updateSearchCriteria: (state, action) => {
      const { key, value } = action.payload;
      state.planAdvancedSearch.searchCriteria = {
        ...state.planAdvancedSearch.searchCriteria,
        [key]: value,
      };
    },
    resetSearchCriteria: (state) => {
      state.planAdvancedSearch.searchCriteria =
        initialState.planAdvancedSearch.searchCriteria;
    },
    setMaterialsTableData: (state, action) => {
      state.materialsTableData = action.payload;
    },
    setToolsTableData: (state, action) => {
      state.toolsTableData = action.payload;
    },
    setPlanCoverImages: (state, action) => {
      state.planCoverImages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchPlansList
      .addCase(fetchPlansList.pending, (state) => {
        state.plansList.error = null;
        state.plansList.loading = true;
      })
      .addCase(fetchPlansList.fulfilled, (state, action) => {
        state.plansList.error = null;
        state.plansList.loading = false;
        state.plansList.data = action.payload;
      })
      .addCase(fetchPlansList.rejected, (state, action) => {
        state.plansList.error = action.error.message;
        state.plansList.loading = false;
      })
      // fetchSearchedPlansList
      .addCase(fetchSearchedPlans.pending, (state) => {
        state.searchedPlansList.error = null;
        state.searchedPlansList.loading = true;
      })
      .addCase(fetchSearchedPlans.fulfilled, (state, action) => {
        state.searchedPlansList.error = null;
        state.searchedPlansList.loading = false;
        state.searchedPlansList.data = action.payload;
      })
      .addCase(fetchSearchedPlans.rejected, (state, action) => {
        state.searchedPlansList.error = action.error.message;
        state.searchedPlansList.loading = false;
      })
      //fetchPlanMaterial
      .addCase(fetchPlanMaterials.pending, (state) => {
        state.planMaterials.error = null;
        state.planMaterials.loading = true;
      })
      .addCase(fetchPlanMaterials.fulfilled, (state, action) => {
        state.planMaterials.error = null;
        state.planMaterials.loading = false;
        state.planMaterials.data = action.payload;
      })
      .addCase(fetchPlanMaterials.rejected, (state, action) => {
        state.planMaterials.error = action.error.message;
        state.planMaterials.loading = false;
      })
      //fetchPlanTools
      .addCase(fetchPlanTools.pending, (state) => {
        state.planTools.error = null;
        state.planTools.loading = true;
      })
      .addCase(fetchPlanTools.fulfilled, (state, action) => {
        state.planTools.error = null;
        state.planTools.loading = false;
        state.planTools.data = action.payload;
      })
      .addCase(fetchPlanTools.rejected, (state, action) => {
        state.planTools.error = action.error.message;
        state.planTools.loading = false;
      })
      //fetchPlanDetail
      .addCase(fetchPlanDetails.pending, (state) => {
        state.planDetail.error = null;
        state.planDetail.loading = true;
      })
      .addCase(fetchPlanDetails.fulfilled, (state, action) => {
        state.planDetail.error = null;
        state.planDetail.loading = false;
        state.planDetail.data = action.payload;
      })
      .addCase(fetchPlanDetails.rejected, (state, action) => {
        state.planDetail.error = action.error.message;
        state.planDetail.loading = false;
      })
      //fetchContentDetails
      .addCase(fetchContentDetails.pending, (state) => {
        state.contentDetails.error = null;
        state.contentDetails.loading = true;
      })
      .addCase(fetchContentDetails.fulfilled, (state, action) => {
        state.contentDetails.error = null;
        state.contentDetails.loading = false;
        state.contentDetails.data = action.payload;
      })
      .addCase(fetchContentDetails.rejected, (state, action) => {
        state.contentDetails.error = action.error.message;
        state.contentDetails.loading = false;
      })
      //fetchContentOverview
      .addCase(fetchContentOverview.pending, (state) => {
        state.contentOverview.error = null;
        state.contentOverview.loading = true;
      })
      .addCase(fetchContentOverview.fulfilled, (state, action) => {
        state.contentOverview.error = null;
        state.contentOverview.loading = false;
        state.contentOverview.data = action.payload;
      })
      .addCase(fetchContentOverview.rejected, (state, action) => {
        state.contentOverview.error = action.error.message;
        state.contentOverview.loading = false;
      })
      //fetchPlanAdvancedSearch
      .addCase(fetchPlanAdvancedSearch.pending, (state) => {
        state.planAdvancedSearch.error = null;
        state.planAdvancedSearch.loading = true;
      })
      .addCase(fetchPlanAdvancedSearch.fulfilled, (state, action) => {
        state.planAdvancedSearch.error = null;
        state.planAdvancedSearch.loading = false;
        state.planAdvancedSearch.data = action.payload;
      })
      .addCase(fetchPlanAdvancedSearch.rejected, (state, action) => {
        state.planAdvancedSearch.error = action.error.message;
        state.planAdvancedSearch.loading = false;
      })
      //fetchMorePlansList
      .addCase(fetchRelatedPlans.pending, (state) => {
        state.morePlansList.error = null;
        state.morePlansList.loading = true;
      })
      .addCase(fetchRelatedPlans.fulfilled, (state, action) => {
        state.morePlansList.error = null;
        state.morePlansList.loading = false;
        state.morePlansList.data = action.payload;
      })
      .addCase(fetchRelatedPlans.rejected, (state, action) => {
        state.morePlansList.error = action.error.message;
        state.morePlansList.loading = false;
      })
      //fetchSelectedPlanTools
      .addCase(fetchSelectedPlanTools.pending, (state) => {
        state.selectedPlanTools.error = null;
        state.selectedPlanTools.loading = true;
      })
      .addCase(fetchSelectedPlanTools.fulfilled, (state, action) => {
        state.selectedPlanTools.error = null;
        state.selectedPlanTools.loading = false;
        state.selectedPlanTools.data = action.payload;
        state.toolsTableData = action.payload;
      })
      .addCase(fetchSelectedPlanTools.rejected, (state, action) => {
        state.selectedPlanTools.error = action.error.message;
        state.selectedPlanTools.loading = false;
      })
      //fetchSelectedPlanMaterials
      .addCase(fetchSelectedPlanMaterials.pending, (state) => {
        state.selectedPlanMaterials.error = null;
        state.selectedPlanMaterials.loading = true;
      })
      .addCase(fetchSelectedPlanMaterials.fulfilled, (state, action) => {
        state.selectedPlanMaterials.error = null;
        state.selectedPlanMaterials.loading = false;
        state.selectedPlanMaterials.data = action.payload;
        state.materialsTableData = action.payload;
      })
      .addCase(fetchSelectedPlanMaterials.rejected, (state, action) => {
        state.selectedPlanMaterials.error = action.error.message;
        state.selectedPlanMaterials.loading = false;
      });
  },
});

export type PlansSlicePartialState = {
  [PlansSlice.name]: IPlansSlice;
  [key: string]: any;
};

export const {
  setPlanId,
  setNewPlan,
  updateCurrentPlan,
  updateCurrentPlanSteps,
  setCurrentPlanSteps,
  updateSearchCriteria,
  resetPlanDetail,
  updateSelectedPlanMaterials,
  updateSelectedPlanTools,
  setMaterialsTableData,
  setToolsTableData,
  setPlanCoverImages,
  updatePlanSteps,
  resetSearchCriteria,
} = PlansSlice.actions;
