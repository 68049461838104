import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { DataProvider } from "../../api/dataProvider";
import { IGarageItem } from "../../interfaces/IGarage";

export const createGarageItem = createAsyncThunk(
  "garages/createGarageItem",
  async (data: IGarageItem, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post(`garages`, data);
      if (result) {
        message.success(
          `${data.type === 0 ? "Material" : "Tool"} added to your garage`
        );
        if (data.type === 0) {
          thunkAPI.dispatch(
            fetchMaterialsList({ pageSize: 500, pageNumber: 1 })
          );
        } else {
          thunkAPI.dispatch(fetchToolsList({ pageSize: 500, pageNumber: 1 }));
        }
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchMaterialsList = createAsyncThunk(
  "garages/fetchMaterialsList",
  async (data: { pageSize: number; pageNumber: number }, thunkAPI?: any) => {
    const { pageSize, pageNumber } = data;
    try {
      const result = await DataProvider.getList(
        `garages/materials?pageSize=${pageSize}&pageNumber=${pageNumber}`
      );

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchToolsList = createAsyncThunk(
  "garages/fetchToolsList",
  async (data: { pageSize: number; pageNumber: number }, thunkAPI?: any) => {
    const { pageSize, pageNumber } = data;
    try {
      const result = await DataProvider.getList(
        `garages/tools?pageSize=${pageSize}&pageNumber=${pageNumber}`
      );

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const deleteGarageItem = createAsyncThunk(
  "garages/deleteGarageItem",
  async (id: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.delete(`garages`, { id });

      if (result) {
        message.success(`Material/Tool deleted successfully`);
        thunkAPI.dispatch(fetchMaterialsList({ pageSize: 20, pageNumber: 1 }));
        thunkAPI.dispatch(fetchToolsList({ pageSize: 20, pageNumber: 1 }));
      }

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const updateGarageItem = createAsyncThunk(
  "garages/updateGarageItem",
  async (data: { id: string; reqBody: any }, thunkAPI?: any) => {
    const { id, reqBody } = data;
    try {
      const result = await DataProvider.update(`garages`, { id, ...reqBody });

      if (result) {
        message.success(
          `${reqBody.type === 0 ? "Material" : "Tool"} updated successfully`
        );
        thunkAPI.dispatch(fetchMaterialsList({ pageSize: 20, pageNumber: 1 }));
        thunkAPI.dispatch(fetchToolsList({ pageSize: 20, pageNumber: 1 }));
      }

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);
