import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataProvider } from "../../api/dataProvider";
import { message } from "antd";
import {
  ChangePasswordData,
  IUser,
  ResetPasswordData,
} from "../../interfaces/IAuth";
import { api } from "../../api/configs/axiosConfig";
import { fetchPlansList } from "./PlansActions";
import { syncLocalStorageCart } from "./PaymentActions";
import { resetPayment } from "../slices/PaymentSlice";

export const userLogin = createAsyncThunk(
  "auth/userLogin",
  async (data: any, thunkAPI?: any) => {
    const { username = "", password, email, remember } = data;

    try {
      const result = await DataProvider.post("accounts/login", {
        username,
        password,
        email,
      });

      // Assuming the token is returned in the response
      const token = result;
      // Save the token in local storage or cookies if needed

      if (token) {
        localStorage.setItem("token", token);
        const cart = localStorage.getItem("cart");
        if (cart) {
          thunkAPI.dispatch(syncLocalStorageCart());
        }
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        message.success("Login successful! Welcome back.");
      }

      return { token, username }; // Return the token and user info
    } catch (error: any) {
      message.error(error.response.data.Message);
      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const userSignup = createAsyncThunk(
  "auth/userSignup",
  async (user: IUser, thunkAPI) => {
    try {
      const response = await DataProvider.post("accounts", user);
      if (response.length > 0) {
        message.success("Sign up successful! Welcome aboard!");
      }
      return response;
    } catch (error: any) {
      message.error(error.response.data.Message);
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const userLogout = createAsyncThunk(
  "auth/userLogout",
  async (_, thunkAPI) => {
    try {
      localStorage.removeItem("token");
      delete api.defaults.headers.common["Authorization"];
      message.success("Logout successful! See you again.");
      const cart = localStorage.getItem("cart");
      if (cart) {
        localStorage.removeItem("cart");
      }
      thunkAPI.dispatch(resetPayment());
      return "logout";
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data || error.message);
    }
  }
);

export const fetchOnlineUserData = createAsyncThunk(
  "auth/getOnlineUserData",
  async (_, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList("accounts/user-info");
      if (result && result?.accountId) {
        thunkAPI.dispatch(fetchUserData(result?.accountId));
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchUserData = createAsyncThunk(
  "auth/getUserData",
  async (userId: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getOne("accounts", { id: userId });

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const updateUserData = createAsyncThunk(
  "auth/updateUserData",
  async (data: { userId: string; reqBody: any }, thunkAPI?: any) => {
    const { userId, reqBody } = data;
    try {
      const result = await DataProvider.update(`accounts/${userId}`, reqBody);
      if (result) {
        message.success("Profile updated successfully!");
        thunkAPI.dispatch(fetchUserData(userId));
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchUserProfilePreview = createAsyncThunk(
  "auth/fetchUserProfilePreview",
  async (userId: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(`accounts/${userId}/preview`);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data: ChangePasswordData, thunkAPI?: any) => {
    try {
      const result = await DataProvider.update(
        "accounts/change-password",
        data
      );
      if (result) {
        message.success("Password changed successfully!");
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const sendResetPasswordLink = createAsyncThunk(
  "auth/sendResetPasswordLink",
  async (email: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(
        `accounts/forget-password/${email}`
      );
      if (result) {
        message.success("Reset password link sent to your email!");
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data: ResetPasswordData, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post("accounts/reset-password", data);
      if (result) {
        message.success("Password reset successful!");
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "auth/deleteAccount",
  async (userId: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.delete(`accounts`, { id: userId });
      if (result) {
        thunkAPI.dispatch(userLogout());
        message.success("Account deleted successfully!");
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  "auth/loginWithGoogle",
  async (data: { credential: string; email: string }, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post(
        "accounts/signin-google-auth",
        data
      );
      if (result) {
        const token = result;
        localStorage.setItem("token", token);
        const cart = localStorage.getItem("cart");
        if (cart) {
          thunkAPI.dispatch(syncLocalStorageCart());
        }
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        message.success("Login successful! Welcome back.");
      }
      return result;
    } catch (error: any) {
      message.error(error.response.data.Message);
      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);
