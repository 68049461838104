import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { DataProvider } from "../../api/dataProvider";
import { IItem } from "../../interfaces/IPayment";
import {
  setCartData,
  setConfirmPayment,
  setShowCartModal,
} from "../slices/PaymentSlice";
import { fetchPlanAdvancedSearch } from "./PlansActions";

export const fetchCartList = createAsyncThunk(
  "payments/fetchCartList",
  async (_, thunkAPI?: any) => {
    const isAuthorized = thunkAPI.getState().auth.isAuthenticated;

    if (!isAuthorized) {
      const cart = localStorage.getItem("cart");
      if (cart) {
        thunkAPI.dispatch(setCartData(JSON.parse(cart)));
        return JSON.parse(cart);
      } else {
        return thunkAPI.rejectWithValue("No cart data found in local storage");
      }
    }

    try {
      const result = await DataProvider.getList(`purchases`);
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

const addToLocalStorageCart = async (newItem: any) => {
  const cart = JSON.parse(localStorage.getItem("cart") || "{}");

  if (cart.items) {
    // Update existing cart
    cart.items.push(newItem);
    cart.count += 1;
    cart.totalPrice += newItem.price * newItem.quantity;
  } else {
    // Create new cart
    cart.items = [newItem];
    cart.count = 1;
    cart.totalPrice = newItem.price * newItem.quantity;
  }

  await localStorage.setItem("cart", JSON.stringify(cart));

  return JSON.parse(localStorage.getItem("cart") || "{}");
};

const removeFromLocalStorageCart = async (planId: string) => {
  const cart = JSON.parse(localStorage.getItem("cart") || "{}");

  if (cart.items) {
    const updatedItems = cart.items.filter(
      (item: IItem) => item.planId !== planId
    );
    const updatedCart = {
      ...cart,
      items: updatedItems,
      count: updatedItems.length,
      totalPrice: updatedItems.reduce(
        (acc: number, item: IItem) => acc + item.price * item.quantity,
        0
      ),
    };

    await localStorage.setItem("cart", JSON.stringify(updatedCart));

    return JSON.parse(localStorage.getItem("cart") || "{}");
  }

  return cart;
};

export const addToCart = createAsyncThunk(
  "payments/addToCart",
  async (planData: any, thunkAPI?: any) => {
    const planId = planData?.id;
    const isUserAuthorized = thunkAPI.getState().auth.isAuthenticated;

    const item: IItem = {
      planId: planData?.id,
      title: planData?.title,
      description: planData?.description,
      price: planData?.price,
      quantity: 1,
      image: planData?.coverImages ? planData?.coverImages[0] : null,
    };

    if (!isUserAuthorized) {
      const cart = JSON.parse(localStorage.getItem("cart") || "{}");
      const isDuplicate = cart.items?.some(
        (cartItem: IItem) => cartItem.planId === item.planId
      );

      if (isDuplicate) {
        message.warning("Item is already in the cart");
        return;
      }

      addToLocalStorageCart(item).then((result) => {
        thunkAPI.dispatch(setCartData(result));
        thunkAPI.dispatch(setShowCartModal(true));
      });

      message.success("Item added to cart successfully ");
      return;
    }

    try {
      const result = await DataProvider.post(`purchases/${planId}`);
      thunkAPI.dispatch(fetchCartList());
      thunkAPI.dispatch(
        fetchPlanAdvancedSearch({
          isFavorite: false,
          pageNumber: 1,
          pageSize: 100,
          searchCriteria: {
            difficulty: null,
            subCategoryId: [],
            minPlanPrice: null,
            maxPlanPrice: null,
            projectCost: null,
            rate: null,
            orderType: null,
            lstRate: null,
            creatorId: null,
            name: null,
          },
        })
      );
      if (result) {
        message.success("Item added to cart successfully");
        thunkAPI.dispatch(setShowCartModal(true));
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const syncLocalStorageCart = createAsyncThunk(
  "payments/syncLocalStorageCart",
  async (_, thunkAPI) => {
    const cart = JSON.parse(localStorage.getItem("cart") || "[]");
    const selectedPlans = cart.items.map((item: IItem) => item.planId);
    try {
      const result = await DataProvider.post(`purchases/Bulk`, selectedPlans);
      if (result) {
        message.success("Cart synced with server successfully");
        localStorage.removeItem("cart");
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "payments/removeFromCart",
  async (planId: any, thunkAPI?: any) => {
    const isUserAuthorized = thunkAPI.getState().auth.isAuthenticated;
    if (!isUserAuthorized) {
      removeFromLocalStorageCart(planId).then((result) => {
        thunkAPI.dispatch(setCartData(result));
      });

      message.success("Item removed from cart successfully");
      return;
    }
    try {
      const result = await DataProvider.delete(`purchases`, { id: planId });
      if (result) {
        message.success("Item removed from cart successfully");
      }
      thunkAPI.dispatch(fetchCartList());
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const createPaymentLink = createAsyncThunk(
  "payments/createPaymentLink",
  async (_, thunkAPI?: any) => {
    const isUserAuthorized = thunkAPI.getState().auth.isAuthenticated;
    if (!isUserAuthorized) {
      message.warning("Please Sign in to proceed with the payment");
      return;
    }
    try {
      const result = await DataProvider.post(
        `payments/create-payment-link?returnUrl=${process.env.REACT_APP_PAYMENT_RETURN_URL_PROD}`
      );
      if (result?.isTotalFree) {
        window.location.href = result.paymentLink;
      } else {
        const linkUrl = result.paymentLink;
        window.location.href = linkUrl;
      }

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const cancelPayment = createAsyncThunk(
  "payments/cancelPayment",
  async (transactionId: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(
        `payments/${transactionId}/cancel`
      );
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const confirmPayment = createAsyncThunk(
  "payments/confirmPayment",
  async (transactionId: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(
        `payments/${transactionId}/success`
      );

      thunkAPI.dispatch(setConfirmPayment(result));
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);
